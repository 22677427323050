import useMediaQuery from "@mui/material/useMediaQuery"
// import * as Clickable from "components/clickable"
import Articles from "components/content/articles"
import FeaturedIn from "components/content/featured-in"
import BanksListView from "components/content/banks-list-view"
import Layout from "components/layout"
import { Typography } from "components/typography"
import { Link, navigate } from "gatsby"
import React, { CSSProperties, useCallback, useEffect, useRef } from "react"
import { use100vh } from "react-div-100vh"
import { LazyLoadImage } from "react-lazy-load-image-component"
import ReactPlayer from "react-player"
import { JsonLd } from "react-schemaorg"
import { Organization } from "schema-dts"
import AppManager from "services/app-manager"
import { DataCollector, DataCollectorAction } from "@sog/sdk"
import smoothscroll from "smoothscroll-polyfill"
import { HomeLoansView } from "components/content/home-loans-page"
import Collapse from "@mui/material/Collapse"
import ExpandLess from "@mui/icons-material/ExpandLess"
import ExpandMore from "@mui/icons-material/ExpandMore"
import HomepageArticleView from "components/content/homepage-article-view"
import { chunkifyArray } from "components/utils"
import { MapToDiv } from "components/content/map-to-div"
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material"
import { ButtonBase } from "@mui/material"
import * as Clickable from "components/clickable"
import { useAuth } from "state/AuthContext"
import { apis as API } from "@sog/sdk"

const Home2 = (props: { position: number | null }) => {
  // const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
  // const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  // //console.log("document.documentElement.clientWidth =  " + document.documentElement.clientWidth + " window.innerWidth = " + window.innerWidth);
  // //console.log("vw =  " + vw + " vh = " + vh);
  // //console.log("window.outerHeigh t =  " + window.outerHeight);
  // window.resizeTo(window.screen.availWidth, window.screen.availHeight);
  // //console.log("window.screen.availWidth =  " + window.screen.availWidth + " window.screen.availHeight = " + window.screen.availHeight);

  // //console.log(props.position);
  const isWide = useMediaQuery("(min-width:768px)")
  const isTablet = !useMediaQuery("(min-width:1024px)")
  const myRef = useRef<HTMLDivElement | null>(null)
  const vc1 = useRef<HTMLDivElement | null>(null)
  const vc2 = useRef<HTMLDivElement | null>(null)
  const vc3 = useRef<HTMLDivElement | null>(null)
  const vc4 = useRef<HTMLDivElement | null>(null)
  const vc5 = useRef<HTMLDivElement | null>(null)
  const vc6 = useRef<HTMLDivElement | null>(null)
  const vo1 = useRef<ReactPlayer | null>(null)
  const vo2 = useRef<ReactPlayer | null>(null)
  const vo3 = useRef<ReactPlayer | null>(null)
  const vo4 = useRef<ReactPlayer | null>(null)
  const vo5 = useRef<ReactPlayer | null>(null)
  const vo6 = useRef<ReactPlayer | null>(null)
  const vph1 = useRef<HTMLImageElement | null>(null)
  const vph2 = useRef<HTMLImageElement | null>(null)
  const vph3 = useRef<HTMLImageElement | null>(null)
  const vph4 = useRef<HTMLImageElement | null>(null)
  const vph5 = useRef<HTMLImageElement | null>(null)
  const vph6 = useRef<HTMLImageElement | null>(null)
  const animationTimerRef = useRef<NodeJS.Timeout>()

  const [currentVideoIndexToPlay, setCurrentVideoIndexToPlay] = React.useState<number>(-1)

  const providerOverviewContent = AppManager.getInstance().getAllProvidersOverviewContent()

  const { userDetails } = useAuth()
  const isLoggedIn = React.useMemo(() => (!!userDetails), [userDetails])

  type Video = {
    url: string
    placeholder: string
    ref: React.MutableRefObject<HTMLDivElement | null>
    ref2: React.MutableRefObject<ReactPlayer | null>
    phRef: React.MutableRefObject<HTMLImageElement | null>
    alt: string
    nextVideoInSequence?: number
    startNextVideoAfter?: number
  }

  const videos: Video[] = [
    {
      url: "/video/StayOrGo_Animation_01.mp4",
      placeholder: "/video/StayOrGo_Animation_01.jpf",
      ref: vc1,
      ref2: vo1,
      phRef: vph1,
      alt: "Should I stay or should I go with a new credit card provider?",
    },
    {
      url: "/video/StayOrGo_Animation_02.mp4",
      placeholder: "/video/StayOrGo_Animation_02.jpf",
      ref: vc2,
      ref2: vo2,
      phRef: vph2,
      alt: "Get a better credit card deal from your bank",
      nextVideoInSequence: 2,
      startNextVideoAfter: 8.0,
    },
    {
      url: "/video/StayOrGo_Animation_03.mp4",
      placeholder: "/video/StayOrGo_Animation_03.jpf",
      ref: vc3,
      ref2: vo3,
      phRef: vph3,
      alt: "Compare personalised credit card offers from across the market",
      nextVideoInSequence: 1,
      // startNextVideoAfter: 6.0,
    },
    {
      url: "/video/StayOrGo_Animation_04.mp4",
      placeholder: "/video/StayOrGo_Animation_04.jpf",
      ref: vc4,
      ref2: vo4,
      phRef: vph4,
      alt: "Relax, with a credit card deal that remains competitive",
    },
    {
      url: "/video/StayOrGo_Animation_05.mp4",
      placeholder: "/video/StayOrGo_Animation_05.jpf",
      ref: vc5,
      ref2: vo5,
      phRef: vph5,
      alt: "We do the maths",
    },
    {
      url: "/video/StayOrGo_Animation_06.mp4",
      placeholder: "/video/StayOrGo_Animation_06.jpf",
      ref: vc6,
      ref2: vo6,
      phRef: vph6,
      alt: "Stay or Go is a free service",
    },
  ]

  // https://stackoverflow.com/a/44506265
  useEffect(() => {
    // componentDidMount
    return () => {
      // componentWillUnmount
      if (animationTimerRef.current) clearTimeout(animationTimerRef.current)
    }
  }, [])

  const playVideoAction = useCallback((video: Record<string, any>) => {
    if (!video.play) {
      return
    }
    video
      .play()
      .then(() => { })
      .catch((error: { toString: () => string }) => {
        //  console.log("play() request ", error)
        if (error && error.toString().toLowerCase().includes("the play() request was interrupted by a call to pause()")) {
          // do nothing, we catch only this exception https://developers.google.com/web/updates/2017/06/play-request-was-interrupted
        } else {
          // throw error
        }
      })
  }, [])

  const isVisible = (
    rect: DOMRect,
    inFullView: boolean = true,
    boundScaleFromCenterHorizontal: number = 1,
    boundScaleFromCenterVertical: number = 1, // scale the bounds of the rect
    viewScaleHorizontal: number = 1,
    viewScaleVertical: number = 1, // scale the bounds of the window
  ) => {
    const navHeight = isWide ? 80 : 60
    const pageViewHeight = window.innerHeight
    const pageViewWidth = window.innerWidth

    const windowCenterX = pageViewWidth / 2
    const windowCenterY = (navHeight + pageViewHeight) / 2

    const scaledWindowTop = windowCenterY - ((pageViewHeight - navHeight) * viewScaleVertical) / 2
    const scaledWindowBottom = windowCenterY + ((pageViewHeight - navHeight) * viewScaleVertical) / 2
    const scaledWindowLeft = windowCenterX - (pageViewWidth * viewScaleHorizontal) / 2
    const scaledWindowRight = windowCenterX + (pageViewWidth * viewScaleHorizontal) / 2

    // top and left from DOMRect are relative to scroll position
    const centerX = rect.left + rect.width / 2
    const centerY = rect.top + rect.height / 2

    // adjust visibility bounds by scale
    const top = centerY - (rect.height * boundScaleFromCenterVertical) / 2
    const bottom = centerY + (rect.height * boundScaleFromCenterVertical) / 2
    const left = centerX - (rect.width * boundScaleFromCenterHorizontal) / 2
    const right = centerX + (rect.width * boundScaleFromCenterHorizontal) / 2

    return inFullView // inFullView means the bounding rect must be completely within the view; otherwise partial overlap allowed
      ? bottom <= scaledWindowBottom && top >= scaledWindowTop && right <= scaledWindowRight && left >= scaledWindowLeft
      : top <= scaledWindowBottom && bottom >= scaledWindowTop && left <= scaledWindowRight && right >= scaledWindowLeft
  }

  let centeredCarouselVideoIndex: number = -1
  let minHorizontalCarouselVideoOffset: number = Number.MAX_VALUE
  let minVerticalCardVideoOffset: number = Number.MAX_VALUE

  videos.forEach((v, i) => {
    if (v.ref.current && v.ref2.current && v.ref2.current.getInternalPlayer()) {
      const boundingRect = v.ref.current.getBoundingClientRect()
      if (i === 0) {
        // play if 125% of bounding area overlaps with current view
        if (isVisible(boundingRect, false, 1.25, 1.25)) {
          playVideoAction(v.ref2.current.getInternalPlayer())
        } else {
          v.ref2.current.getInternalPlayer().pause()
        }
      } else {
        const scaleBoundsHorizontal = 1.25
        const scaleBoundsVertical = 1.25
        if (isVisible(boundingRect, false, scaleBoundsHorizontal, scaleBoundsVertical)) {
          if (!isWide) {
            playVideoAction(v.ref2.current.getInternalPlayer())
            // const horizontalOffset = Math.abs(window.innerWidth / 2 - (boundingRect.left + boundingRect.width / 2))
            // if (horizontalOffset < minHorizontalCarouselVideoOffset) {
            //   minHorizontalCarouselVideoOffset = horizontalOffset
            //   centeredCarouselVideoIndex = i
            // }
            const verticalOffset = Math.abs((window.innerHeight - 60) / 2 + 60 - (boundingRect.top + boundingRect.height / 2))
            if (verticalOffset < minVerticalCardVideoOffset) {
              minVerticalCardVideoOffset = verticalOffset
              centeredCarouselVideoIndex = i
            }
          } else {
            if (typeof v.nextVideoInSequence === "number") {
              if (currentVideoIndexToPlay < 0 && i === 1) {
                playVideoAction(v.ref2.current.getInternalPlayer())
                setCurrentVideoIndexToPlay(i)
              } else {
                if (currentVideoIndexToPlay === i) {
                  playVideoAction(v.ref2.current.getInternalPlayer())
                }
              }
            } else {
              playVideoAction(v.ref2.current.getInternalPlayer())
            }
          }
        } else {
          if (isVisible(boundingRect, false, scaleBoundsHorizontal * 1.5, scaleBoundsVertical * 1.5)) {
            v.ref2.current.getInternalPlayer().pause()
          } else {
            v.ref2.current.getInternalPlayer().pause()
            v.ref2.current.getInternalPlayer().currentTime = 0
          }
        }
      }
    }
  })

  if (!isWide && centeredCarouselVideoIndex > 0) {
    videos.forEach((v, i) => {
      if (i > 0) {
        if (i !== centeredCarouselVideoIndex && v.ref2.current) {
          v.ref2.current.getInternalPlayer().pause()
        }
      }
    })
  }

  const getVideo = (num: number, style?: React.CSSProperties) => {
    num = num - 1
    return (
      <div ref={videos[num].ref} className="img-container" style={style}>
        {videos[num].url && (
          <ReactPlayer
            onReady={() => {
              const curr = videos[num].phRef.current
              if (curr) {
                curr.style.display = "none"
              }
            }}
            onError={() => {
              const curr = videos[num].phRef.current
              if (curr) {
                curr.style.display = "block"
              }
            }}
            onEnded={() => {
              const nextVideoIndex = videos[num].nextVideoInSequence
              if (isWide && typeof nextVideoIndex === "number") {
                const curr = videos[num].ref2.current
                const next = videos[nextVideoIndex].ref2.current
                if (next && !next.getInternalPlayer().playing) {
                  playVideoAction(next.getInternalPlayer())
                  setCurrentVideoIndexToPlay(nextVideoIndex)
                } else if (curr) {
                  playVideoAction(curr.getInternalPlayer())
                  setCurrentVideoIndexToPlay(num)
                }
              }
            }}
            onProgress={data => {
              const nextVideoIndex = videos[num].nextVideoInSequence
              const playNextVideoAfter = videos[num].startNextVideoAfter
              if (isWide && typeof nextVideoIndex === "number" && typeof playNextVideoAfter === "number") {
                if (playNextVideoAfter <= data.playedSeconds) {
                  const next = videos[nextVideoIndex].ref2.current
                  if (next && !next.getInternalPlayer().playing) {
                    playVideoAction(next.getInternalPlayer())
                    setCurrentVideoIndexToPlay(nextVideoIndex)
                  }
                }
              }
            }}
            ref={videos[num].ref2}
            url={videos[num].url}
            playing={num === 0}
            style={{
              // position: "absolute",
              top: 0,
              backgroundColor: "white",
              left: 0,
            }}
            volume={0}
            muted={true}
            loop={!isWide || typeof videos[num].nextVideoInSequence !== "number"}
            playsinline={true}
            width="100%"
            height="100%"
          />
        )}
        <img ref={videos[num].phRef} className="img" style={{ height: "calc(100% - 2px)" }} src={videos[num].placeholder} alt={videos[num].alt} />
      </div>
    )
  }

  const scrollDown = () => {
    smoothscroll.polyfill()
    if (typeof window !== `undefined` && myRef.current) {
      window.scrollTo({
        behavior: "smooth",
        top: myRef.current.offsetTop - (isWide ? -100 : 80),
      })
    }
  }

  const landingPageHeading = () => {
    return (
      <div>
        <Typography.H2 comp="landing-page">
          Should I stay <br></br>or should I go.
        </Typography.H2>
      </div>
    )
  }

  const landingPageSubheading = () => {
    return (
      <div>
        <Typography.H3 comp="landing-page">
          {isWide ? (
            "What will your bank offer you to stay?"
          ) : (
            <>
              What will your bank offer <br />
              you to stay?
            </>
          )}
        </Typography.H3>
      </div>
    )
  }

  const comparePagesView = () => {
    // let lowestRate = Number.MAX_VALUE
    // Object.values(hlProviders).forEach(provider => {
    //   Object.values(provider.products).forEach(product => {
    //     Object.values(product.rates).forEach(rate => {
    //       if (rate.interestRate < lowestRate) {
    //         lowestRate = rate.interestRate
    //       }
    //     })
    //   })
    // })

    // const [open, setOpen] = React.useState(false)

    const comparePages = [
      {
        title: "Home loans",
        // description: "Rates from " + (lowestRate * 100).toLocaleString() + "%",
        description: "Top home loan rates",
        url: "/home-loans",
        imgUrl: "images/icon_home_loan.png",
      },
      {
        title: "Credit cards",
        description: "0% rates, rewards",
        url: "/credit-cards",
        imgUrl: "images/icon_credit_card.png",
      },
      {
        title: "Personal loans",
        description: "Top personal loan rates",
        url: "/personal-loans",
        imgUrl: "images/icon_personal_loan.png",
      },
      {
        title: "Car loans",
        description: "Top car loan rates",
        url: "/car-loans",
        imgUrl: "images/icon_car_loan.png",
      },
      {
        title: "Term deposits",
        description: "Top term deposit rates",
        url: "/term-deposits",
        imgUrl: "images/icon_term_deposit.png",
      },
      {
        title: "Bank accounts",
        description: "Top everyday accounts",
        url: "/bank-accounts",
        imgUrl: "images/icon_transaction_account.png",
      },
      {
        title: "Savings accounts",
        description: "High interest savings accounts",
        url: "/savings-accounts",
        imgUrl: "images/icon_savings_account.png",
      },
      {
        title: "Buy now pay later",
        description: "(coming soon)",
        url: "/buy-now-pay-later",
        imgUrl: "images/icon_buy_now_pay_later.png",
      },
    ]

    const pageCell = (page: { title: string; description: string; url: string; imgUrl: string }, style: React.CSSProperties = {}) => {
      return (
        <div
          className="category-link-cell-button"
          style={style}
          key={`page-cell-${page.title}`}
          onClick={() => {
            DataCollector.getInstance().addAction(DataCollectorAction.CONTENT_CATEGORY_SELECTED, { category: page.url })
            navigate(page.url)
          }}
        >
          <div className="category-link-cell-button-image-container">
            <LazyLoadImage style={{ width: "100%" }} src={page.imgUrl} className="category-link-cell-button-image-container" />
          </div>
          <Typography.P comp="category-link-cell-button-text" style={{ maxWidth: "100%" }}>
            <div style={{ maxWidth: "100%", overflowX: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{page.title}</div>
          </Typography.P>
        </div>
      )
    }

    const makePageCellRows = (itemsPerRow: number) => {
      return (
        <div className="category-link-cell-grid" style={{ marginTop: isWide ? "64px" : "50px", gridTemplateColumns: `repeat(${itemsPerRow}, minmax(0,1fr))`, maxWidth: isWide && isTablet ? "none" : "none" }}>
          {comparePages.map((p, i) => {
            return pageCell(p, { gridRow: 1 + Math.floor(i / itemsPerRow), gridColumn: 1 + (i % itemsPerRow) })
          })}
        </div>
      )
    }

    return (
      <div style={isWide ? {} : {}}>
        <Typography.H2 comp="homepage-section-heading" style={{ textAlign: "center" }}>
          Find the perfect financial product for you
        </Typography.H2>
        <Typography.P comp="homepage-section-subtitle" style={{ textAlign: "center" }}>
          Compare interest rates, fees and features across all the major banks and popular brands.
        </Typography.P>
        {makePageCellRows(isWide ? (isTablet ? 4 : 4) : 2)}
      </div>
    )
  }

  const sections = [
    {
      title: "Get a better deal from your bank",
      subTitle: "Instantly access a better deal without needing to change banks or providers",
      videoIndex: 1,
    },
    {
      title: "Compare leading offers from across the market",
      subTitle: "Hundreds of deals and offers from all the major banks and challengers",
      videoIndex: 2,
    },
    {
      title: "Relax, we crunch the numbers. For free.",
      subTitle: "Find great deals for the long term, and we'll help you ensure they stay competitive",
      videoIndex: 3,
    },
    // {
    //   title: "Get a better deal from your bank",
    //   subTitle: "Instantly access a better deal without needing to change providers or reapply.",
    // },
    // {
    //   title: "Compare leading offers from across the market",
    //   subTitle: "Access special deals and compare relevant product offers from all the major banks and leading challengers.",
    // },
    // {
    //   title: "Relax, knowing we have your back",
    //   subTitle: "We focus on finding great deals for the long term. And, we can monitor your deal to ensure it remains competitive.",
    // },
    // {
    //   title: "We do the maths",
    //   subTitle: "We make it super easy to compare, so you won’t need a calculator to decide which is the best product for you.",
    // },
    // {
    //   title: "It’s free",
    //   subTitle: "The great news is, Stay or Go is a free service, so get started now to find out how much you could save.",
    // },
  ]

  const mobileWhyCompareSection = (section: { title: string; subTitle: string }, videoIndex: number, firstItem?: boolean) => {
    return (
      <div className="why-compare-carousel-item-container" style={firstItem ? { marginLeft: 0 } : {}} key={`why-compare-item-${videoIndex - 1}`}>
        <div className="why-compare-carousel-item-content-container">
          <div style={{ textAlign: "center" }}>
            <Typography.H3 comp="why-compare-item-heading">{section.title}</Typography.H3>
            <Typography.P comp="why-compare-detail">{section.subTitle}</Typography.P>
          </div>
          <div style={{ width: "100%", height: "120px" }}>{getVideo(videoIndex)}</div>
        </div>
      </div>
    )
  }

  const desktopWhyCompareSection = (section: { title: string; subTitle: string }, videoIndex: number, style?: CSSProperties) => {
    return (
      <div style={{ ...style }} key={`why-compare-item-${videoIndex - 1}`}>
        <div className="why-compare-grid-item-content-container">
          <div style={{ width: "63%" }}>
            <Typography.H3 comp="why-compare-item-heading">{section.title}</Typography.H3>
            <Typography.P comp="why-compare-detail">{section.subTitle}</Typography.P>
          </div>
          <div style={{ width: "35%" }}>{getVideo(videoIndex)}</div>
        </div>
      </div>
    )
  }

  const meetTheTeamSection = (style: React.CSSProperties = {}) => {
    return !isTablet ? (
      <div style={{ margin: "auto", ...style }}>
        <Typography.H3 comp="why-compare-meet-the-team-heading">Learn more about us</Typography.H3>
        <Clickable.Text component={Link} to={"/about/"} secondary style={{ margin: "auto", width: "200px", height: "60px" }} comp={7}>
          Meet the team
        </Clickable.Text>
      </div>
    ) : (
      <div className="why-compare-carousel-item-container" style={{ marginRight: 0 }}>
        <div className="why-compare-carousel-item-content-container-meet-the-team">
          <Typography.H3 comp="why-compare-meet-the-team-heading">Learn more about us</Typography.H3>
          {/* Can't use Clickable.Text here, for some reason it doesn't build when wrapped in a div
        which has ::-webkit-scrollbar in the SCSS */}
          <div className="meet-the-team-mobile-button" onClick={() => navigate("/about/")}>
            Meet the team
          </div>
        </div>
      </div>
    )
  }

  const whyCompareSection = () => {
    const carouselScrollRef = useRef<HTMLDivElement | null>(null)
    const [carouselScrollPosition, setCarouselScrollPosition] = React.useState(0)

    React.useEffect(() => {
      if (isWide) {
        setCarouselScrollPosition(0)
      }
    }, [isWide])

    const onCarouselScroll = () => {
      if (carouselScrollRef.current) {
        if (carouselScrollRef.current.scrollWidth > carouselScrollRef.current.clientWidth) {
          setCarouselScrollPosition(carouselScrollRef.current.scrollLeft / (carouselScrollRef.current.scrollWidth - carouselScrollRef.current.clientWidth))
        } else {
          setCarouselScrollPosition(0)
        }
      }
    }

    return (
      <div className="why-compare-section-container">
        <Typography.H2 comp="homepage-section-heading" style={{ textAlign: "center" }}>
          Why compare at Stay or Go?
        </Typography.H2>
        <Typography.P comp="homepage-section-subtitle" style={{ textAlign: "center" }}>
          We strive to simplify the process and provide you with the well-deserved peace of mind.
        </Typography.P>
        <div className={isWide ? "why-compare-card-container-desktop" : "why-compare-card-container-mobile"}>
          {sections.map((s, i) => {
            return (
              <div className="why-compare-card-item" style={i === 0 ? { marginTop: "0" } : {}}>
                <div className="why-compare-card-item-video-container">{getVideo(s.videoIndex + 1)}</div>
                <div className="why-compare-card-item-text-container">
                  <Typography.H3 comp="why-compare-item-heading">{s.title}</Typography.H3>
                  <Typography.P comp="why-compare-detail">{s.subTitle}</Typography.P>
                </div>
              </div>
            )
          })}
        </div>
        {/* {!isTablet ? <div className="why-compare-grid-container">
        {sections.map((s,i) => {
          const row = Math.floor(i / 2) + 1
          const column = (i % 2) + 1
          return desktopWhyCompareSection(s,i+2,{gridColumn: `${column}`, gridRow: `${row} / span 1`})
        })}
        {meetTheTeamSection(sections.length % 2 === 0
          ? { gridColumn: "1 / span 2", gridRow: `${(sections.length / 2) + 1}` }
          : { gridColumn: "2", gridRow: `${(sections.length + 1) / 2}` }
        )}
      </div> : <div ref={carouselScrollRef} className="why-compare-carousel-container" onScroll={() => onCarouselScroll()}>
        {sections.map((s,i) => mobileWhyCompareSection(s,i+2,i===0))}
        {meetTheTeamSection()}
      </div>}
      {isTablet && <div className="why-compare-carousel-progress-markers-container">
        {[...sections.map((s,i) => i), sections.length].map((n) => {
          const scrollItemCurrent = Math.round(carouselScrollPosition * (sections.length))
          const fillColour = (scrollItemCurrent === n) ? "#00afc5" : "white"
          return <svg key={`marker-${n}`} viewBox="0 0 10 10" fill={fillColour} stroke="#00afc5" width="10" height="10" className="why-compare-carousel-progress-marker-svg">
            <circle r="4" cx="5" cy="5"/>
          </svg>
        })}
      </div>} */}
      </div>
    )
  }

  const landingPage = (headingElement: JSX.Element, subHeadingElement: JSX.Element, video?: JSX.Element, showArrow?: boolean, scrollDownAction?: () => void) => {
    // TEMP: arrow stuff
    const height = use100vh() || 960
    const allowArrow = (isWide ? (height >= 720 && height <= 1080) : (height >= 620 && height <= 850))

    const surveyButton = (firstLine: string, categoryName: string, imageSrc: string, to: string, alt: string) => (
      <div className="landing-page-button-container-new">
        <Clickable.View
          className="landing-page-button-new"
          onClick={() => {
            if (isLoggedIn) {
              API.validateCurrentProperties({}, responseJson => {
                if (responseJson.hasRequest) {
                  navigate("home-loans/review-in-progress", {
                    state: {
                      ...responseJson,
                    },
                  })
                } else {
                  navigate(to)
                }
              })
            } else {
              navigate(to)
            }
            DataCollector.getInstance().addAction(DataCollectorAction.GET_STARTED, { buttonId: "HOME_TOP", productCategory: "HOME_LOANS" })
          }}
        >
          <div style={{ width: "100%", padding: isWide ? "23px 30px 30px 20px" : "20px 15px 15px" }}>
            {isWide ? (
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <img src={imageSrc} alt={alt} style={{ maxHeight: "72px" }} />
                <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", textAlign: "right" }}>
                  <Typography.P comp="landing-page-button">{firstLine}</Typography.P>
                  <Typography.P comp="landing-page-button-bold">{categoryName}</Typography.P>
                </div>
              </div>
            ) : (
              <>
                <img src={imageSrc} alt={alt} style={{ marginBottom: "16px", maxHeight: "55px" }} />
                <Typography.P comp="landing-page-button">{firstLine}</Typography.P>
                <Typography.P comp="landing-page-button-bold">{categoryName}</Typography.P>
              </>
            )}
            <div style={{ float: "right", margin: isWide ? "25px auto 0px" : "36px -5px -5px auto", backgroundColor: "#00afc5", width: "54px", height: "50px", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={"/images/icon_arrow_white.png"} style={{ maxHeight: "24px" }} />
            </div>
          </div>
        </Clickable.View>
      </div>
    )

    const surveyButtons = () => (
      <div className="landing-page-bottom-part-new">
        {surveyButton("Reduce your", "home loan", "/images/house_cta.png", "/home-loans/instructions/", "Home loan survey icon")}
        <div style={{ flex: `0 1 ${isWide ? "60px" : "20px"}` }} />
        {surveyButton("Improve your", "credit card", "/images/card_cta.png", "/credit-cards/instructions/", "Credit card survey icon")}
      </div>
    )

    return (
      <div className="landing-page-container" style={(allowArrow && showArrow) ? {} : { height: "fit-content" }}>
        <JsonLd<Organization>
          item={{
            "@context": "https://schema.org",
            "@type": "Organization",
            name: "Stay or Go",
            url: "https://www.stayorgo.com.au/",
            logo: "/images/Logo_SOG_Colour.png",
            location: "Australia",
            description: "With Stay or Go, you no longer need to switch banks for a better deal.",
          }}
        />
        {isWide ? (
          <div className="landing-page-section-container">
            <div className="container-horizontal container--space-between" style={{ alignItems: "stretch" }}>
              <div className="landing-page-section-left-new">{headingElement}</div>
              {video && <div className="landing-page-section-right-new">{video}</div>}
            </div>
            {subHeadingElement}
          </div>
        ) : (
          <div className="landing-page-section-container">
            {headingElement}
            {subHeadingElement}
          </div>
        )}
        {surveyButtons()}
        {scrollDownAction && allowArrow && <div ref={myRef} className="container-bottom-center">
          <div role="button" tabIndex={0} className="arrow-button" onKeyDown={() => scrollDownAction()} onClick={() => scrollDownAction()} style={showArrow ? { opacity: 1 } : { opacity: 0, display: "none" }}>
            <div className="img-container">
              <LazyLoadImage className="img" src="/images/scroll_arrow.png" alt="" />
            </div>
          </div>
        </div>}
      </div>
    )
  }

  const brandsWeCompareSection = () => {
    const itemsPerRow = isWide ? 4 : 2
    const rowsPerGroup = isWide ? 3 : 4

    const providersCarouselScrollRef = useRef<HTMLDivElement | null>(null)
    const [providerPageSelected, setProviderPageSelected] = React.useState(0)

    const items = Object.values(providerOverviewContent)
    items.sort((a, b) => {
      if (a.big4 !== b.big4) {
        return a.big4 ? -1 : 1
      }
      if (a.popular !== b.popular) {
        return a.popular ? -1 : 1
      }
      return a.id < b.id ? -1 : 1
    })
    const pages = chunkifyArray(chunkifyArray(items, itemsPerRow), rowsPerGroup)

    const setScrollPage = (index: number) => {
      if (index < 0) {
        index = pages.length - 1
      }
      if (index >= pages.length) {
        index = 0
      }
      if (providersCarouselScrollRef.current) {
        providersCarouselScrollRef.current.scrollLeft = providersCarouselScrollRef.current.clientWidth * index
      }
      setProviderPageSelected(index)
    }

    useEffect(() => {
      setScrollPage(0)
    }, [isWide])

    if (pages.length === 0) {
      return <div />
    }

    return (
      <div className="why-compare-section-container" id="providers">
        <Typography.H2 comp="homepage-section-heading" style={{ textAlign: "center" }}>
          Brands we compare
        </Typography.H2>
        <Typography.P comp="homepage-section-subtitle" style={{ textAlign: "center" }}>
          Compare offers from all the major banks and popular brands.
        </Typography.P>
        <div className="brands-we-compare-content-container">
          <div className="brands-we-compare-arrow-container" style={{ marginRight: "4px" }} onClick={() => setScrollPage(providerPageSelected - 1)}>
            <ArrowBackIosNew style={{ maxHeight: "24px" }} className="brands-we-compare-arrow" />
          </div>
          <div className="brands-we-compare-carousel-container" ref={providersCarouselScrollRef}>
            <div className="brands-we-compare-carousel">
              {pages.map((p, i) => {
                return (
                  <div key={`banks-carousel-group-${i}`} className="brands-we-compare-carousel-item-container">
                    <MapToDiv.Banks banks={p} />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="brands-we-compare-arrow-container" style={{ marginLeft: "4px" }} onClick={() => setScrollPage(providerPageSelected + 1)}>
            <ArrowForwardIos style={{ maxHeight: "24px" }} className="brands-we-compare-arrow" />
          </div>
        </div>
        <div className="why-compare-carousel-progress-markers-container" style={{ marginTop: "32px" }}>
          {pages.map((p, n) => {
            const fillColour = providerPageSelected === n ? "#00afc5" : "white"
            return (
              <svg key={`marker-${n}`} viewBox="0 0 10 10" fill={fillColour} stroke="#00afc5" width="10" height="10" className="why-compare-carousel-progress-marker-svg">
                <circle r="4" cx="5" cy="5" />
              </svg>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="container-center">
      {isWide && (
        <div className="home-sections show-for-desktop">
          <div className="home-section-group">
            <div className="home-container">
              {landingPage(landingPageHeading(), landingPageSubheading(), getVideo(1), (props.position === 0), scrollDown)}
            </div>
          </div>
          <div className="home-section-group">
            <div className="home-container">{comparePagesView()}</div>
          </div>
          <div className="home-section-group" style={{ marginTop: "228px" }}>
            <div className="home-container">{whyCompareSection()}</div>
          </div>
          <div className="home-section-group" style={{ marginTop: "80px" }}>
            <div className="home-container">
              {/* <HomeLoansView indexPage /> */}
              {brandsWeCompareSection()}
              {/* <Articles /> */}
              <HomepageArticleView />
              <FeaturedIn />
            </div>
          </div>
        </div>
      )}
      {!isWide && (
        <div className="home-sections show-for-mobile">
          <div className="home-section-group">
            <div className="home-container">{landingPage(landingPageHeading(), landingPageSubheading())}</div>
          </div>
          <div className="home-section-group">
            <div className="home-container">{comparePagesView()}</div>
          </div>
          <div className="home-section-group" style={{ marginTop: "60px" }}>
            <div className="home-container">{whyCompareSection()}</div>
          </div>
          <div className="home-section-group" style={{ marginTop: "60px" }}>
            <div className="home-container">
              {/* <HomeLoansView indexPage /> */}
              {brandsWeCompareSection()}
              {/* <Articles /> */}
              <HomepageArticleView />
              <FeaturedIn />
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

class Home extends React.Component<{}, { position: number | null }> {
  timer: NodeJS.Timeout | null
  constructor(props: {}) {
    super(props)
    this.state = {
      position: typeof window !== `undefined` ? window.scrollY : null,
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.timer = null
  }

  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.addEventListener("scroll", this.handleScroll, { passive: true })
    }
  }

  componentWillUnmount() {
    if (typeof window !== `undefined`) {
      window.removeEventListener("scroll", this.handleScroll)
    }
    if (this.timer) clearTimeout(this.timer)
  }

  handleScroll(e: any) {
    // do something like call `this.setState`
    // access window.scrollY etc
    // this.setState();
    if (this.timer) clearTimeout(this.timer)
    else
      this.setState({
        position: typeof window !== `undefined` ? window.scrollY : null,
      })
    let timeoutCallback = () => {
      // //console.log("animation stopped");
      this.setState({
        position: typeof window !== `undefined` ? window.scrollY : null,
      })
      this.timer = null
    }
    timeoutCallback = timeoutCallback.bind(this)
    this.timer = setTimeout(timeoutCallback, 300)

    // clearTimeout(timer1);

    // this.setState({ position: window.pageYOffset });
    // //console.log(window.pageYOffset);
  }

  render() {
    return <Home2 position={this.state.position} />
  }
}

const Index = () => (
  <Layout title="Get a better Home Loan, Credit Card deal without switching" description="Compare the top Home Loan, Credit Card, Personal Loan, Savings, Term Deposit offers from across the market. See what your bank will offer you to stay." image="/video/StayOrGo_Animation_01.jpf">
    <Home />
  </Layout>
)
export default Index
